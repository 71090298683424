import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import HeaderMarkets from "../../components/headers/header-markets";
import Help from "../../components/global/help";
import Projects from "../../components/global/projects";
import IconUx from "../../assets/images/markets/ux.svg";
import IconKey from "../../assets/images/markets/key.svg";
import IconFingerprint from "../../assets/images/markets/fingerprint.svg";
import IconRobot from "../../assets/images/markets/robot.svg";
import PromiseAppEngIt from "../../components/markets/promise-app-eng-it";
import HeaderBcg from "../../assets/images/headers/e-commerce.png";

const ECommerce = () => {
  const { t } = useTranslation();
  // Header Content
  const hTitle = `${t("markets.eCommerce")}`;
  const hShortText = `${t("marketsPages.eCommerce.shortText")}`;
  const hText = `${t("marketsPages.eCommerce.text")}`;
  const helpHeadline = `${t("marketsPages.helpHeadline")}`;

  // Promise Component
  const helpContent = [
    {
      title: `${t("marketsPages.eCommerce.helpContent.firstCard.title")}`,
      textOne: `${t("marketsPages.eCommerce.helpContent.firstCard.textOne")}`,
      textTwo: `${t("marketsPages.eCommerce.helpContent.firstCard.textTwo")}`,
      img: <IconUx />,
    },
    {
      title: `${t("marketsPages.eCommerce.helpContent.secondCard.title")}`,
      textOne: `${t("marketsPages.eCommerce.helpContent.secondCard.textOne")}`,
      textTwo: `${t("marketsPages.eCommerce.helpContent.secondCard.textTwo")}`,
      img: <IconKey />,
    },
    {
      title: `${t("marketsPages.eCommerce.helpContent.thirdCard.title")}`,
      textOne: `${t("marketsPages.eCommerce.helpContent.thirdCard.textOne")}`,
      textTwo: `${t("marketsPages.eCommerce.helpContent.thirdCard.textTwo")}`,
      img: <IconFingerprint />,
    },
    {
      title: `${t("marketsPages.eCommerce.helpContent.fourthCard.title")}`,
      textOne: `${t("marketsPages.eCommerce.helpContent.fourthCard.textOne")}`,
      textTwo: `${t("marketsPages.eCommerce.helpContent.fourthCard.textTwo")}`,
      img: <IconRobot />,
    },
  ];
  return (
    <Layout>
      <Seo title={hTitle} />
      <HeaderMarkets
        hTitle={hTitle}
        hShortText={hShortText}
        hText={hText}
        bkgImage={HeaderBcg}
        hNum={"95"}
      />
      <Help helpContent={helpContent} helpHeadline={helpHeadline} />
      <PromiseAppEngIt promiseContentItems={["app-dev", "eng-team"]} />
      <Projects />
    </Layout>
  );
};

export default ECommerce;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
